import {ref} from '@vue/composition-api';
import store from '@/store/index';

const useReleasesPg = () => {
  // base data
  const releasesPg = ref (null);
  const loading = ref (false);

  // methods
  const getReleasesPg = async () => {
    loading.value = true;
    const {data, status} = await store.dispatch ('fetchReleasesPg');
    loading.value = false;
    if (status != 200) return;
    releasesPg.value = data;
    return data;
  };

  const updateReleasesPg = async releasesPg => {
    loading.value = true;
    const {data, status} = await store.dispatch (
      'updateReleasesPg',
      releasesPg
    );
    loading.value = false;
    if (status != 200 && status != 201) return;
    return data;
  };

  return {
    releasesPg,
    loading,
    // methods
    getReleasesPg,
    updateReleasesPg,
  };
};

export default useReleasesPg;
