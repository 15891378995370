<template>
  <v-card>
    <ReleasesPgHeader></ReleasesPgHeader>
    <section class="form-full-width">
      <FormReleasesPg :loading="loading" :getReleasesPg="getReleasesPg" @beforeUpdate="updateReleasesPg"></FormReleasesPg>
    </section>
  </v-card>
</template>

<script>
import FormReleasesPg from '@/components/forms/FormReleasesPg.vue'
import ReleasesPgHeader from './ReleasesPgHeader.vue'
import useReleasesPg from '@/composables/useReleasesPg'

export default {
  components: {
    FormReleasesPg,
    ReleasesPgHeader,
  },
  setup() {
    // Composition API
    const {
      releasesPg,
      loading,
      // methods
      getReleasesPg,
      updateReleasesPg,
    } = useReleasesPg()

    return {
      releasesPg,
      loading,
      // methods
      getReleasesPg,
      updateReleasesPg,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-full-width {
  padding: 10px;
}
</style>
